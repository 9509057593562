import React, { useState, useEffect } from 'react';
import { Button, Form, Col, InputGroup } from 'react-bootstrap';
import '../styles/DonationForm.css';
import { useAccount, useConnect, useNetwork } from 'wagmi'
import { ethers } from 'ethers';
import { useNavigate } from 'react-router-dom';

import { useLogicContract, useTokenContract, getTokenContract } from '../hooks/useWalletConnect';
import { TokenList } from '../config/tokens';
const DonationForm = () => {
    const [token, setToken] = useState('');
    const [tokenAddress, setTokenAddress] = useState('');
    const [amount, setAmount] = useState('0');
    const [decimals, setDecimals] = useState(18);
    const [frequency, setFrequency] = useState('0');
    const [loading, setLoading] = useState(false);
    const [buttonCaption, setButtonCaption] = useState("Donate");
    const [stopButton, setStopButton] = useState("Stop Donation");
    const [donateStore, setDonateStore] = useState(null);

    // const { write, data, isLoading, isSuccess } = useSetDonation(tokenAddress, amount, frequency);
    const { address, isConnected } = useAccount();
    const { chain, chains } = useNetwork()
    const { tokenContract, signer, provider } = useTokenContract(tokenAddress);
    const { logicContract } = useLogicContract();
    const navigate = useNavigate();

    const tokens = Object.keys(TokenList);

    function getTokenByAddressAndChain(address, chainId) {
        for (let token in TokenList) {
            if (TokenList[token][chainId].toLowerCase() === address.toLowerCase()) {
                return token;
            }
        }
        return null;
    }


    useEffect(() => {
        console.log("Current Chain: ", chain);
        console.log("Token Address: ", tokenAddress);
        console.log("Token Amount: %s convert to %s", amount, ethers.parseUnits(amount, decimals));
        console.log("Frequency: ", frequency);

        if (loading == false) {
            if (donateStore == null || ethers.formatUnits(donateStore[1], 6) == '0' ) {
                if (frequency != '0') {
                    setButtonCaption("Approve");
                }
                else {
                    setButtonCaption("Donate");
                }
            } else {
                if (frequency == '0')
                    setButtonCaption("Donate");
                else
                    setButtonCaption("Change Donation");
            }
            async function updateDecimals() {
                setLoading(true);
                if(tokenContract && tokenContract.runner) {
                    const dec = await tokenContract.decimals();
                    setDecimals(dec);
                }
                setLoading(false);
            }
            updateDecimals();
        }

        //console.log("ethers convertion: 1: ", ethers.formatUnits(ethers.parseUnits("1", 6), 9));

        // async function getSigner() {
        //     if (window.ethereum) {
        //       const p = new ethers.BrowserProvider(window.ethereum);
        //       const s = await p.getSigner();
        //       console.log("Signer:", s); // Here's your signer

        //       // To get the address of the signer, you can do:
        //       const address =  s.address;
        //       console.log(address); // Here's the address of the signer
        //     }
        //   }

        //   getSigner();

    }, [tokenAddress, amount, frequency, decimals])

    useEffect(() => {
        console.log("Account address: ", address);
        async function loadDonationStore() {
            if (!logicContract.runner) {
                return;
            }

            if (donateStore == null || ethers.formatUnits(donateStore[1], 6) == '0') {
                const [taddress, amt, bm] = await logicContract.donationStore(address);
                console.log("Donation Store: ", taddress, amt, bm);
                if (taddress == ethers.ZeroAddress) {
                    console.log("No Donation");
                } else {
                    setTokenAddress(taddress);
                    setToken(getTokenByAddressAndChain(taddress, chain.id));
                    const tokenContract = getTokenContract(taddress, signer);
                    const dec = await tokenContract.decimals();
                    setAmount((+ethers.formatUnits(amt, dec)).toFixed(4));
                    setFrequency(bm ? '2' : '1');
                    setDonateStore([taddress, amt, bm]);
                    setButtonCaption("Change Donation");
                }
            }
            // setIsInitialized(true);
        }
        loadDonationStore();

    }, [logicContract.runner, donateStore, frequency])

    const initialize = () => {
        setToken('');
        setTokenAddress('');
        setAmount('0');
        setFrequency('0');
        setDonateStore(null);
    }

    const checkConnects = () => {
        if (!isConnected) {
            return false;
        }
        if (!logicContract) {
            return false;
        }
        return true;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!checkConnects()) {
            alert('Make sure that you are connected to your wallet!');
            return;
        }
        if (!tokenContract) {
            alert('Make sure you selected a token');
            return;
        }
        if (parseInt(amount) == 0) {
            alert('Make sure you input correct amount');
            return;
        }
        // Handle form submission and interact with the smart contract
        const logicAddr = await logicContract.getAddress();
        const tokenAddr = await tokenContract.getAddress();
        console.log("logicContract.address: ", logicAddr);
        console.log("tokenContract.address: ", tokenAddr);
        let actualAmount = ethers.parseUnits(amount, decimals);
        try {
            if (frequency === '0') {
                // Approve token spend with Permit for unlimited (if possible)
                // Transfer token directly to the specific contract addres
                setLoading(true);
                setButtonCaption("Waiting on approval...");
                const tx = await tokenContract.transfer(logicAddr, actualAmount);
                setButtonCaption("Thank you!!!");
                await tx.wait();
                setButtonCaption("Donate");
                setLoading(false);
            } else {
                // Approve token spend for unlimited (do not use Permit!)
                const _m = frequency === '2';
                const signerAddr = signer.address;

                console.log("Signer Adderss:", signerAddr);
                console.log("Account Address:", address);

                let allowance = await tokenContract.allowance(signerAddr, logicAddr);
                console.log("MAX UINT: ", ethers.MaxUint256)
                console.log("Allowance: ", allowance)
                setLoading(true);
                if (allowance == ethers.parseUnits("0", 1)) {
                    setButtonCaption("Waiting on approval...");
                    const tx = await tokenContract.approve(logicAddr, ethers.MaxUint256);
                    await tx.wait(1);
                }
                allowance = await tokenContract.allowance(signerAddr, logicAddr);
                if (actualAmount > allowance)
                    actualAmount = allowance;
                console.log("Actual Amount:", actualAmount);
                setButtonCaption("Waiting on approval...");
                const tx1 = await logicContract.setDonation(tokenAddress, actualAmount, _m);
                setButtonCaption("Thank you!!!");
                await tx1.wait();
                setButtonCaption("Change Donation");
                setLoading(false);

            }
            initialize();
        } catch (e) {
            setLoading(false);
            alert(e.message);
            setButtonCaption("Change Donation");
        }
    };

    const handleStopDonate = async (event) => {
        event.preventDefault();
        setLoading(true);
        if (!checkConnects()) {
            alert('Make sure that you are connected to your wallet!');
            return;
        }
        try {
            // Approve token spend for unlimited (do not use Permit!)
            const _m = donateStore[2];
            setStopButton("Stopping donation...");
            const tx1 = await logicContract.setDonation(tokenAddress, 0, _m);
            setStopButton("Thank you!!!");
            await tx1.wait();
            setStopButton("Stop Donation");
            setLoading(false);
            initialize();
        } catch (e) {
            setLoading(false);
            setStopButton("Stop Donation");
            alert(e.message);
        }
    }
    const tokenChanged = async (e) => {
        // e.preventDefault();
        if (!checkConnects()) {
            alert("Make sure you are connected!");
            return;
        }
        const val = e.target.value;
        if (val) {
            setToken(val);
            setTokenAddress(TokenList[val][chain.id]);
        }
        else {
            setToken("");
            setTokenAddress("");
        }
    }
    const amountChanged = (e) => {
        // e.preventDefault();
        const val = e.target.value;
        if (!val || val == undefined)
            setAmount('0');
        else
            setAmount(val);
    }

    const freqChanged = (e) => {
        //e.preventDefault();
        const val = e.target.value;
        if (!val || val == undefined)
            setFrequency('0');
        else
            setFrequency(val);

        // if (frequency != '0')
        //     setStatus("Approve");
        // else
        //     setStatus("Donate");
    }

    const checkDisable = () => {
        return (loading);
    }

    return (
        <Form className="donation-form" onSubmit={handleSubmit}>
            {/* Token selection */}
            <Form.Group as={Col} controlId="formGridToken">
                <Form.Label>Token</Form.Label>
                <Form.Control as="select" value={token} disabled={checkDisable()} onChange={e => tokenChanged(e)}>
                    <option value="">Select a token</option>
                    {tokens.map((option, index) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </Form.Control>
            </Form.Group>
            <br></br>
            {/* Amount input */}
            <Form.Group as={Col} controlId="formGridAmount">
                <Form.Label>Amount</Form.Label>
                <InputGroup>
                    <Form.Control
                        type="number"
                        step="0.01"
                        value={amount}
                        disabled={checkDisable()}
                        onChange={e => amountChanged(e)}
                        placeholder="Enter amount"
                    />
                </InputGroup>
            </Form.Group>
            <br></br>
            {/* Frequency selection */}
            <Form.Group as={Col}>
                <Form.Label>Frequency</Form.Label>
                <Form.Check
                    type="radio"
                    label="One Time"
                    name="frequency"
                    value="0"
                    checked={frequency === '0'}
                    disabled={checkDisable()}
                    onChange={e => freqChanged(e)}
                />
                <Form.Check
                    type="radio"
                    label="Weekly"
                    name="frequency"
                    value="1"
                    checked={frequency === '1'}
                    disabled={checkDisable()}
                    onChange={e => freqChanged(e)}
                />
                <Form.Check
                    type="radio"
                    label="Monthly"
                    name="frequency"
                    value="2"
                    checked={frequency === '2'}
                    disabled={checkDisable()}
                    onChange={e => freqChanged(e)}
                />
            </Form.Group>
            <br></br>
            <Button variant="primary" type="submit" disabled={checkDisable()}>
                {buttonCaption}
            </Button>
            {
                (donateStore && donateStore[0] !== ethers.ZeroAddress && ethers.formatEther(donateStore[1]) != '0') && (
                    <Button variant="secondary" type="button" onClick={(e) => handleStopDonate(e)} disabled={checkDisable()}>
                        {stopButton}
                    </Button>
                )
            }
        </Form>
    );
};

export default DonationForm;
