import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import './App.css';

import Header from './components/Header';
import DonationForm from './pages/DonationForm';
import Admin from './pages/Admin';
import PrivateRouteWrapper from './components/PrivateRouteWrapper';

import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/react'
import { configureChains, createConfig, WagmiConfig } from 'wagmi'
// import { arbitrum, arbitrumGoerli, aurora, auroraTestnet, avalanche, avalancheFuji, base, baseGoerli, boba, bronos, bronosTestnet, bsc, bscTestnet, bxn, bxnTestnet, canto, celo, celoAlfajores, celoCannoli, cronos, cronosTestnet, crossbell, dfk, dogechain, edgeware, edgewareTestnet, evmos, evmosTestnet, fantom, fantomTestnet, fibo, filecoin, filecoinCalibration, filecoinHyperspace, flare, flareTestnet, foundry, gnosis, gnosisChiado, goerli, haqqMainnet, haqqTestedge2, hardhat, harmonyOne, iotex, iotexTestnet, klaytn, lineaTestnet, localhost, mainnet, mantle, mantleTestnet, metis, metisGoerli, mev, mevTestnet, moonbaseAlpha, moonbeam, moonriver, nexi, nexilix, okc, optimism, optimismGoerli, polygon, polygonMumbai, polygonZkEvm, polygonZkEvmTestnet, pulsechain, pulsechainV4, scrollTestnet, sepolia, shardeumSphinx, skaleBlockBrawlers, skaleCalypso, skaleCalypsoTestnet, skaleChaosTestnet, skaleCryptoBlades, skaleCryptoColosseum, skaleEuropa, skaleEuropaTestnet, skaleExorde, skaleHumanProtocol, skaleNebula, skaleNebulaTestnet, skaleRazor, skaleTitan, skaleTitanTestnet, songbird, songbirdTestnet, syscoin, taikoTestnetSepolia, taraxa, taraxaTestnet, telos, telosTestnet, thunderTestnet, wanchain, wanchainTestnet, xdc, xdcTestnet, zhejiang, zkSync, zkSyncTestnet, zora, zoraTestnet } from 'wagmi/chains'
import { mainnet, bsc, polygon, pulsechain, avalanche} from 'wagmi/chains'


const chains = [mainnet, bsc, polygon, pulsechain, avalanche];

const projectId = process.env.REACT_APP_PROJECT_ID

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient
})
const ethereumClient = new EthereumClient(wagmiConfig, chains)


function App() {

  useEffect(() => {
    // TODO: Initialize web3 and provider when address changes
    console.log("Project ID: ", projectId)
  }, []);

  return (
    <WagmiConfig config={wagmiConfig}>
      <div className="App">
        <Router>
          <header className="App-header">
            <Header />
          </header>
          <div>
            <Routes>
              <Route path='/' element={<DonationForm />} />
              <Route path="/admin" element={<PrivateRouteWrapper><Admin /></PrivateRouteWrapper>} />
            </Routes>
          </div>
        </Router>
      </div>
      <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
    </WagmiConfig>
  );
}

export default App;
