import * as React from 'react'
import { useState, useEffect } from 'react';
import { usePrepareContractWrite, useContractWrite, useWaitForTransaction } from 'wagmi';
import { useDebounce } from './useDebounce';
import { useEthersProvider, useEthersSigner } from './ethers';
import {ethers} from 'ethers'
import CONTRACT_ABI from '../config/abis/contract.json';
import { CONTRACT_ADDRESS } from '../config/constants';
import ERC20_ABI from '../config/abis/erc20.json';

export const useSetDonation = (tokenAddress, amount, type) => {

    //const signer = useEthersSigner();
    //console.log(etherUnits.parseU);


    const debouncedAmount = useDebounce(amount, 500);
    const debouncedAddress = useDebounce(tokenAddress, 500);
    const debouncedType = useDebounce(type, 500);

    const { config } = usePrepareContractWrite({
        address: CONTRACT_ADDRESS,
        abi: CONTRACT_ABI.abi,
        functionName: 'setDonation',
        args: [debouncedAddress, debouncedAmount, debouncedType],
        enabled: Boolean(amount > 0),
    })

    const { data, write } = useContractWrite(config)

    const { isLoading, isSuccess } = useWaitForTransaction({
        hash: data?.hash,
    })

    // const [setDonation, setSetDonation] = useState(write);
    // const [tx, setTx] = useState(data);
    // const [loading, setLoading] = useState(isLoading);
    // const [success, setSuccess] = useState(isSuccess);
    return { write, data, isLoading, isSuccess }
};

export const useTokenContract = (address) => {
    
    const signer = useEthersSigner();
    //console.log(etherUnits.parseU);
    const provider = useEthersProvider();
    const tokenContract = address ? new ethers.Contract(address, ERC20_ABI.abi, signer) : undefined;
    return {signer, provider, tokenContract};
}
export const useLogicContract =() => {
    const signer = useEthersSigner();
    const provider = useEthersProvider();
    const logicContract = new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI.abi, signer);
    return {signer, provider, logicContract};
}
export const getTokenContract =  (address, signer) => {
    const contract = new ethers.Contract(address, ERC20_ABI.abi, signer);
    return contract;
}
export const getLogicContract =  (signer) => {
    const contract = new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI.abi, signer);
    return contract;
}
