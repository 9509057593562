export const TokenList = {

// TODO: refactor this list to be organized by CHAIN -> Token
    DAI: {
        // Ethereum
        1: '0x6b175474e89094c44da98b954eedeac495271d0f',
        // Goerli (ethereum testnet)
        5: '0xdc31Ee1784292379Fbb2964b3B9C4124D8F89C60',
        // Binance
        56: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
        // Polygon
        137: '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
        // PulseChain (total trash, but supported)
        369: '0xefD766cCb38EaF1dfd701853BFCe31359239F305',
        // Hardhat
        31337: localStorage.getItem('USDC_CONTRACT_ADDRESS_HH') || '0xc6e7DF5E7b4f2A278906862b61205850344D4e7d',
        // Avalanche
        43114: '0xd586e7f844cea2f87f50152665bcbc2c279d8d70'
    },

    USDC: {
        1: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
        5: '0x14957d1dE21Fd7369D685e4CE038a437703FB993',
        56: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
        137: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
        369: '0x15D38573d2feeb82e7ad5187aB8c1D52810B1f07',
        43114: '0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e'
    },

    USDT: {
        1: '0xdac17f958d2ee523a2206206994597c13d831ec7',
        5: '0xd12902384e790650BC8A9eBbF75fB5FE481f1e06',
        56: '0x55d398326f99059fF775485246999027B3197955',
        137: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
        369: '0x0Cb6F5a34ad42ec934882A05265A7d5F59b51A2f',
        43114: '0x9702230a8ea53601f5cd2dc00fdbc13d4df4a8c7'
    }

}
