import React, { useState } from 'react';
import { useWeb3Modal } from '@web3modal/react';
import { useAccount, useConnect, useDisconnect } from 'wagmi';
import { InjectedConnector } from 'wagmi/connectors/injected';

import { useLocation } from 'react-router-dom';


const Header = () => {
    const location = useLocation();
    const { open, close } = useWeb3Modal()
    const { address, isConnected } = useAccount()
    const { connect } = useConnect({
      connector: new InjectedConnector(),
    })
    const { disconnect } = useDisconnect();

    // Determine the title based on the current path
    const getTitle = () => {
        switch(location.pathname) {
            case '/admin':
                return 'Donation Admin Dashboard';
            case '/':
            default:
                return 'Genius Marketing Donation';
        }
    };

    return (
        <>
            <h1 className="App-title">{ getTitle() }</h1>
            {
                isConnected ?
                    (<>
                        {/* <h2 > Connected to {address} </h2> */}
                        <button className="wallet-connect-button" onClick={() => disconnect()}> Disconnect </button>
                    </>
                    ) : <button className="wallet-connect-button" onClick={() => open()}> Connect </button>
            }
        </>
    );
};

export default Header;
