import React, { useState, useEffect } from 'react';
import { buttonNames, rolesConfig } from '../config/admin-roles'
import '../styles/Admin.css';

const Admin = (props) => {
  // Add your logic here to determine the admin role
  // For demonstration, let's assume the role is 'Owner'
  const [address, setAddress] = useState('');
  const [authorized, setAuthorized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabledButtons, setDisabledButtons] = useState({});

  useEffect(() => {
    console.log("Form Address: ", address);
    console.log("Form Authorized: ", authorized);
  }, [address, authorized]);

  if (!props.userRole) return;


  // const currentRoleConfig = rolesConfig["Owner"];
  const currentRoleConfig = rolesConfig[props.userRole];

  const logicContract = props.logicContract;


  const handleButtonClick = async (buttonId) => {
    try {
      setLoading(true);
      // Set the button as disabled after clicking
      setDisabledButtons(prevState => ({
        ...prevState,
        [buttonId]: true
      }));

      let tx;
      switch (buttonId) {
        case 'set_ben':
          tx = await logicContract.setBeneficiary(address);
          break;
        case 'retire':
          tx = await logicContract.retire();
          break;
        case 'claim':
          // claimToken(address _token)
          tx = await logicContract.claimToken(address);
          break;
        case 'set_token':
          // setToken(address _token, bool _authorized)
          tx = await logicContract.setToken(address, authorized);
          break;
        case 'add_auth':
          // addAuthAccount
          tx = await logicContract.addAuthAccount(address);
          break;
        case 'rem_auth':
          tx = await logicContract.removeAuthAccount(address);
          break;
        // ... cases for other buttons ...
        case 'proc_donations':
          tx = await logicContract.processDonations();
          break;
        default:
          console.log("Unknown action");
      }
      await tx.wait(1);
      setLoading(false);
      setDisabledButtons(prevState => ({
        ...prevState,
        [buttonId]: false
      }));
    } catch (error) {
      console.log(error);
      if (buttonId != "retire" && !address) {
        alert("Make sure the address field is correct!");
      } else {
        alert("You are not authorized or transaction is reverted!");
      }
      setLoading(false);
      setDisabledButtons(prevState => ({
        ...prevState,
        [buttonId]: false
      }));
    }
  };

  const addressChanged = (e) => {
    setAddress(e.target.value);
  }

  const authChanged = (e) => {
    setAuthorized(e.target.checked);
  }

  return (
    <div className="admin-container">

      {/* Section 1 */}
      <section className="section1">
        <form className="admin-form">
          <label htmlFor="address">Address:</label>
          <input type="text" id="address" name="address" value={address} onChange={e => addressChanged(e)} placeholder="Enter Address" disabled={loading} />
          <label htmlFor="authorized">Authorized:</label>
          <input type="checkbox" id="authorized" name="authorized" onChange={e => authChanged(e)} checked={authorized} disabled={loading} />
        </form>

        <button className="process-btn" key="proc_donations" onClick={() => handleButtonClick("proc_donations")} disabled={disabledButtons["proc_donations"]}>{buttonNames['proc_donations']}</button>
      </section>

      {/* Section 2 */}
      <section className="section2">
        <h2>{currentRoleConfig.title}</h2>
        <div className="grid-buttons">
          {currentRoleConfig.buttons.map(buttonId => (
            <button key={buttonId} onClick={() => handleButtonClick(buttonId)} disabled={disabledButtons[buttonId]}>
              {buttonNames[buttonId]}
            </button>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Admin;
