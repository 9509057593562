export const rolesConfig = {
    'AuthorizedUser': {
        title: 'Authorized Users Can DO',
        buttons: ['set_token', 'add_auth', 'rem_auth']
    },
    'Owner': {
        title: 'Owner / Vice Owner Can Do',
        buttons: ['set_ben', 'retire', 'claim', 'set_token', 'add_auth', 'rem_auth']
    },
    'ViceOwner': {
        title: 'Owner / Vice Owner Can Do',
        buttons: ['set_ben', 'retire', 'claim', 'set_token', 'add_auth', 'rem_auth']
    },
    'Beneficiary': {
        title: 'Beneficiary Can Do',
        buttons: ['claim', 'set_token', 'add_auth', 'rem_auth']
    }
};

export const buttonNames = {
    set_ben: "Set Beneficiary",
    retire: "Retire",
    claim: "Claim Token",
    set_token: "Set Token",
    add_auth: "Add Auth",
    rem_auth: "Remove Auth",
    proc_donations: "Process Donations"
}
