import React, { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useLogicContract } from '../hooks/useWalletConnect';
import { useAccount, useConnect, useNetwork } from 'wagmi';

const PrivateRouteWrapper = ({ children }) => {
  let location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [userRole, setUserRole] = useState(null);
  const { logicContract } = useLogicContract();
  const { address } = useAccount();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let timeout = setTimeout(() => {
      console.log("PrivateRouterWrapper is called");
      if (!logicContract.runner) {
        alert('Error connecting to the wallet. Make sure you are connected.');
      }
      checkAndSetRole();
    }, 3000);
    const checkAndSetRole = async () => {
      if (logicContract && logicContract.runner) {
        setIsLoading(false);
        const [authenticated, role] = await checkAuthentication(logicContract);
        console.log("isAuthenticated: ", authenticated);
        console.log("Role: ", role);
        if (role) {
          setUserRole(role); // Assuming your contract returns a role string/number
        }
        setIsAuthenticated(authenticated);
      } else {
        setIsLoading(false);
        setIsAuthenticated(false);
      }
    }

    return () => clearTimeout(timeout);

  }, [logicContract.runner]);

  const checkAuthentication = async (contract) => {
    console.log("Contract: ", contract)
    try {
      // Calling the smart contract function
      // const authAccounts = await contract.authorizedAccount();
      const beneficiary = await contract.beneficiary();
      const owner = await contract.owner();
      const viceOwner = await contract.viceOwner();

      let isAuth = false, role = null, i = 0;
      switch (address) {
        case beneficiary:
          isAuth = true;
          role = "Beneficiary";
          break;
        case owner:
          isAuth = true;
          role = "Owner";
          break;
        case viceOwner:
          isAuth = true;
          role = "ViceOwner";
          break;
        default:
          isAuth = false;
          role = null;
      }
      if (!isAuth) {
        while (true) {
          const authAccount = await contract.authorizedAccount(i);
          if (authAccount == address) {
            isAuth = true;
            role = "AuthorizedUser";
            break;
          }
          i++;
        }
      }
      return [isAuth, role];
    } catch (error) {
      console.error("Error checking authentication:", error);
      return [false, null];
    }
  };

  if (isLoading) {
    return (<div>Loading.... </div>)
  } else {
    if (!isAuthenticated) {
      return <Navigate to="/" state={{ from: location }} />
    } else {
      return React.cloneElement(children, { userRole, logicContract: logicContract });
    }
  }
};

export default PrivateRouteWrapper;